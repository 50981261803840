import { notFound } from 'next/navigation';

import { nextRedirect } from '../redirect/next-redirect';

export default function handleResponse(response: Response) {
  if (response.status === 401) {
    nextRedirect({
      path: '/auth/sign-out?message=Su%20sesi%C3%B3n%20ha%20caducado%20o%20no%20tiene%20permisos%20para%20realizar%20esta%20acci%C3%B3n',
    });
  }

  if (response.status === 404) {
    notFound();
  }
}
