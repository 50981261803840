export enum ProjectsQueryCacheKey {
  ACTIVITIES = 'projects-activities',
  DETAILS = 'projects-details',
  LIST = 'projects-list',
  PRODUCTION_REVIEWS = 'projects-production-reviews',
  SELECT_LIST = 'projects-select-list',
  UPDATE_IMAGE = 'project-update-mage',
}

export enum ActivitiesCacheKey {
  DETAILS = 'project-activities-details',
  LIST = 'project-activities-list',
  SELECT_LIST = 'project-activities-select-list',
}

export enum ActivitiySchedulesCacheKey {
  DETAILS = 'activities-schedule-details',
  LIST = 'activities-schedule-list',
  SELECT_LIST = 'activities-schedule-select-list',
}

export enum ProjectAreasCacheKey {
  LIST = 'project-areas-list',
  SELECT_LIST = 'project-areas-select-list',
}

export enum StatusesCacheKey {
  LIST = 'statuses-list',
  SELECT_LIST = 'statuses-select-list',
}

export enum StatusTypesCacheKey {
  LIST = 'status-types-list',
  SELECT_LIST = 'status-types-select-list',
}

export enum MeasurementUnitsCacheKey {
  CONVERSIONS_LIST = 'measurement-units-conversions-list',
  DETAILS = 'measurement-units-conversions-detail',
  LIST = 'measurement-units-list',
  SELECT_LIST = 'measurement-units-select-list',
}

export enum MeasurementsCacheKey {
  DETAILS = 'measurements-details',
  LIST = 'measurements-list',
  SELECT_LIST = 'measurements-select-list',
}

export enum LaborTypesCacheKey {
  LIST = 'labor-types-list',
  SELECT_LIST = 'labor-types-select-list',
}

export enum LaborsQueryCacheKey {
  LIST = 'labors-list',
  REPORT = 'labors-report',
  SELECT_LIST = 'labors-select-list',
}

export enum MachineryCacheKey {
  LIST = 'machinery-list',
  SELECT_LIST = 'machinery-select-list',
}

export enum MachineryFamilyCacheKey {
  LIST = 'machinery-family-list',
  SELECT_LIST = 'machinery-family-select-list',
}

export enum MachineryTypeCacheKey {
  LIST = 'machinery-type-list',
  SELECT_LIST = 'machinery-type-select-list',
}

export enum MachineryAdjustmentCacheKey {
  DETAILS = 'machinery-adjustment-details',
  LIST = 'machinery-adjustment-list',
}

export enum MaterialsCacheKey {
  LIST = 'materials-list',
  SELECT_LIST = 'materials-select-list',
}

export enum SchedulesCacheKey {
  DETAIL = 'schedule-details',
  INTERNAL = 'schedule-internal',
  LIST = 'schedule-list',
  SELECT_LIST = 'schedule-select-list',
}

export enum MaterialTypesCacheKey {
  LIST = 'material-types-list',
  SELECT_LIST = 'material-types-select-list',
}

export enum ProjectCategoriesCacheKey {
  LIST = 'project-categories-list',
  SELECT_LIST = 'project-categories-select-list',
}

export enum ProjectScheduleCacheKey {
  LIST = 'project-schedules-list',
  SELECT_LIST = 'project-schedules-select-list',
}

export enum ActivityChecksCacheKey {
  DETAILS = 'project-activity-checks-details',
  LIST = 'project-activity-checks-list',
  SELECT_LIST = 'project-activity-checks-select-list',
}

export enum CountryCacheKey {
  DETAILS = 'country-details',
  LIST = 'country-list',
  SELECT_LIST = 'country-select-list',
}

export enum CityCacheKey {
  LIST = 'city-list',
  SELECT_LIST = 'city-select-list',
}

export enum DepartmentCacheKey {
  DETAILS = 'department-details',
  LIST = 'department-list',
  SELECT_LIST = 'department-select-list',
}

export enum GendersCacheKey {
  LIST = 'gender-list',
  SELECT_LIST = 'gender-select-list',
}

export enum MeasurementUnitsTypesCacheKey {
  LIST = 'measurement-units-types-list',
  SELECT_LIST = 'measurement-units-types-select-list',
}

export enum RolesCacheKey {
  CLAIMS = 'roles-claims',
  DETAILS = 'roles-details',
  LIST = 'roles-list',
  SELECT_LIST = 'roles-select-list',
  USERS = 'roles-users',
}

export enum UsersCacheKey {
  CURRENT = 'users-current',
  DETAILS = 'users-details',
  LIST = 'users-list',
  SELECT_LIST = 'users-select-list',
}

export enum ConversionCacheKey {
  LIST = 'conversion-list',
  SELECT_LIST = 'conversion-select-list',
}

export enum ReportsCacheKey {
  PROJECT_CUMULATIVE = 'reports-project-cumulative',
  PROJECT_CUMULATIVE_COST = 'reports-project-cumulative-cost',
  PROJECT_FINANCIAL_PROGRESS = 'reports-project-financial-progress',
  PROJECT_PHYSICAL_PROGRESS = 'reports-project-physical-progress',
  PROJECT_SCHEDULE_TRACKING = 'reports-project-schedule-tracking',
  PROJECT_SUPPLIES_CONSUMED = 'reports-project-supplies-consumed',
  TIME_TABLE_PRODUCTION_PROGRESS = 'reports-time-table-production-progress',
}

export enum ActivityTypeCacheKey {
  LIST = 'activity-type-list',
  SELECT_LIST = 'activity-type-select-list',
}

export enum MachineryPriceHistoryCacheKey {
  LIST = 'machinery-price-history-list',
  SELECT_LIST = 'machinery-price-history-select-list',
}
export enum MaterialPriceHistoryCacheKey {
  LIST = 'material-price-history-list',
  SELECT_LIST = 'material-price-history-select-list',
}
export enum LaborPriceHistoryCacheKey {
  LIST = 'labor-price-history-list',
  SELECT_LIST = 'labor-price-history-select-list',
}

export enum LaborAdjustmentCacheKey {
  LIST = 'labor-adjustment-list',
}
export enum MaterialAdjustmentCacheKey {
  LIST = 'material-adjustment-list',
}

export enum IncomeTrackCacheKey {
  DETAILS = 'income-track-details',
  LIST = 'income-track-list',
}

export enum KPISCacheKey {
  ACTIVITIES_EXPECTED_UNEXPECTED = 'expected-unexpected-kpi',
  ACTIVITIES_STATUSES = 'activities-statuses-kpi',
  ACTIVITIES_WITH_ADDITIONAL = 'activities-with-additional-kpi',
  ACTIVITIES_WITH_SAVINGS = 'activities-with-savings-kpi',
  CUMULATIVE_COMPARATIVE = 'cumulative-comparative-kpi',
  DIRECT_COST = 'direct-cost-kpi',
  INCIDENCE_OF_ACTIVITIES = 'incidence-of-activities-kpi',
  INDIRECT_COST = 'indirect-cost-kpi',
  INDIRECT_COST_COMPARATIVE = 'indirect-cost-comparative-kpi',
  PROFABILITY_COSTO_NORMADO = 'profability-costo-normado-kpi',
  PROFABILITY_TRACKING_INCOME = 'profability-tracking-income-kpi',
  PROFABILITY_TRACKING_UTILIY = 'profability-tracking-utiliy-kpi',
  PROJECT_COMPARATIVE = 'project-comparative-kpi',
  TRACKIN_INDIRECT_COST = 'tracking-indirect-cost-kpi',
}

export enum ConstraintsCacheKeys {
  LIST = 'constraints-list',
  LIST_SELECT = 'constraints-list-select',
}

export enum ContractorsCacheKey {
  LIST = 'contractors-list',
  LIST_SELECT = 'contractors-list-select',
}

export enum ExchangeRatesCacheKey {
  DETAIL = 'exchange-rates-detail',
  LIST = 'exchange-rates-list',
}

export enum ActivityScheduleConstraintsKeys {
  ADDDOCUMENT = 'activity-schedule-constraints-document',
  DETAIL = 'activity-schedule-constraints-detail',
  LIST = 'activity-schedule-constraints-list',
  LIST_SELECT = 'activity-schedule-constraints-list-select',
}

export enum PlanningDashboardCacheKey {
  CONSTRAINTSSUMMARY = 'constraints-summary',
  CRITICALPHYSICALPROGRESS = 'critical-physical-progress',
  FINANCIALPROGRESS = 'financial-progress',
  PRODUCTIONCOMPARATIVE = 'production-comparative',
  TIMETABLEINDICATORS = 'timetable-indicators',
}

export enum LabelsCacheKey {
  LIST = 'labels-list',
  SELECT_LIST = 'labels-select-list',
}
