import { auth } from '@/auth';

import queryString from 'query-string';

import { UsersCacheKey } from '@/lib/types/api/cache-keys';
import { UsersCacheTime } from '@/lib/types/api/cache-times';
import { ApiPaginationOptions, ApiResponse, ApiResponseWithPagination, ContentType, HttpMethod } from '@/lib/types/api/common';
import {
  ChangePasswordPayload,
  ChangePasswordUserPayload,
  CurrentUserInfo,
  FortgotPasswordData,
  ListSelectUsersData,
  UpdateUserPayload,
  UserAuthenticateData,
  UserInfoData,
  UserListData,
} from '@/lib/types/api/user/user';

import handleResponse from '@/lib/utils/functions/handle-response/handle-response';

export namespace User {
  export const baseUrl = `${process.env.NEXT_PUBLIC_APP_ORIGIN_API}/users`;

  export async function Authenticate({ email, password }: { email: string; password: string }): Promise<ApiResponse<UserAuthenticateData>> {
    const response = await fetch(`${baseUrl}/authenticate`, {
      body: JSON.stringify({ loginCredential: email, password }),
      headers: { 'Content-Type': ContentType.JSON },
      method: HttpMethod.POST,
    });

    return response.json();
  }

  export async function UserInfo(access_token?: string): Promise<ApiResponse<CurrentUserInfo>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/current-user-info`, {
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.GET,
    });

    handleResponse(response);

    return response.json();
  }

  export async function List(payload: ApiPaginationOptions, access_token?: string): Promise<ApiResponseWithPagination<UserListData[]>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const url = queryString.stringifyUrl({
      query: payload as queryString.ParsedQuery<string>,
      url: baseUrl,
    });

    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.GET,
      next: { revalidate: UsersCacheTime.LIST, tags: [UsersCacheKey.LIST] },
    });

    handleResponse(response);

    return response.json();
  }

  export async function Block(id: string, access_token?: string): Promise<ApiResponse<UserInfoData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/${id}/lock`, {
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.PUT,
    });

    handleResponse(response);

    return response.json();
  }

  export async function Detail(id: string, access_token?: string): Promise<ApiResponse<UserListData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/${id}`, {
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.GET,
      next: { revalidate: UsersCacheTime.DETAILS, tags: [UsersCacheKey.DETAILS] },
    });

    handleResponse(response);

    return response.json();
  }

  export async function Update(id: string, payload: UpdateUserPayload, access_token?: string): Promise<ApiResponse<UserInfoData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/${id}`, {
      body: JSON.stringify(payload),
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.PUT,
    });

    handleResponse(response);

    return response.json();
  }
  export async function UpdateSelfProfilePicture(payload: FormData, access_token?: string): Promise<ApiResponse<UserInfoData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }
    const response = await fetch(`${baseUrl}/update-profile-picture`, {
      body: payload,
      headers: { Authorization: `Bearer ${access_token}` },
      method: HttpMethod.PUT,
    });

    handleResponse(response);

    return response.json();
  }

  export async function UpdateProfilePictureById(payload: FormData, id: string, access_token?: string): Promise<ApiResponse<UserInfoData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/${id}/update-profile-picture`, {
      body: payload,
      headers: { Authorization: `Bearer ${access_token}` },
      method: HttpMethod.PUT,
    });

    handleResponse(response);

    return response.json();
  }

  export async function RoleRemove(id: string, roles: string, access_token?: string): Promise<ApiResponse<UserInfoData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/${id}/remove-roles`, {
      body: JSON.stringify({
        roleId: roles,
      }),
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.PUT,
    });

    handleResponse(response);

    return response.json();
  }

  export async function RoleAdd(id: string, roles: string, access_token?: string): Promise<ApiResponse<UserInfoData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/${id}/add-roles`, {
      body: JSON.stringify({
        roleId: roles,
      }),
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.PUT,
    });

    handleResponse(response);

    return response.json();
  }

  export async function ChangePassword(payload: ChangePasswordPayload, access_token?: string): Promise<ApiResponse<UserAuthenticateData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/change-password`, {
      body: JSON.stringify({
        newPassword: payload.newPassword,
        oldPassword: payload.oldPassword,
      }),
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.PUT,
    });

    handleResponse(response);

    return response.json();
  }

  export async function ChangeUserPassword(payload: ChangePasswordUserPayload, access_token?: string): Promise<ApiResponse<UserInfoData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/${payload.userId}/change-password`, {
      body: JSON.stringify({
        newPassword: payload.newPassword,
      }),
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.PUT,
    });

    handleResponse(response);

    return response.json();
  }

  export async function Create(payload: FormData, access_token?: string): Promise<ApiResponse<UserInfoData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(baseUrl, {
      body: payload,
      headers: { Authorization: `Bearer ${access_token}` },
      method: HttpMethod.POST,
    });

    handleResponse(response);

    return response.json();
  }

  export async function CurrentUser(access_token?: string): Promise<ApiResponse<UserListData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/current-user-info`, {
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.GET,
    });

    handleResponse(response);

    return response.json();
  }

  export async function ForgotPassword({ email }: { email: string }): Promise<ApiResponse<FortgotPasswordData>> {
    const response = await fetch(`${baseUrl}/forgot-password`, {
      body: JSON.stringify({ email }),
      headers: { 'Content-Type': ContentType.JSON },
      method: HttpMethod.POST,
    });

    handleResponse(response);

    return response.json();
  }

  export async function ResetPassword(email: string, code: string, newPassword: string): Promise<ApiResponse<FortgotPasswordData>> {
    const url = queryString.stringifyUrl({
      query: {
        code: code,
        email: email,
      },
      url: `${baseUrl}/reset-password`,
    });

    const response = await fetch(`${url}`, {
      body: JSON.stringify({ newPassword }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });

    handleResponse(response);

    return response.json();
  }

  export async function ListSelectUsers(role?: string, access_token?: string): Promise<ApiResponse<ListSelectUsersData[]>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/${role ? `list-select?role=${role}` : 'list-select'}`, {
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.GET,
      next: { revalidate: UsersCacheTime.SELECT_LIST, tags: [UsersCacheKey.SELECT_LIST] },
    });

    handleResponse(response);

    return response.json();
  }

  export async function AssingProjects(userId: string, projects: string[], access_token?: string): Promise<ApiResponse<UserInfoData>> {
    if (!access_token) {
      const session = await auth();
      access_token = session?.access_token;
    }

    const response = await fetch(`${baseUrl}/${userId}/update-projects`, {
      body: JSON.stringify({
        projects: projects,
      }),
      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': ContentType.JSON },
      method: HttpMethod.PUT,
    });

    handleResponse(response);

    return response.json();
  }
}
